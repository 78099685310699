<template>
  <div>
    <iframe class="IPC-Iframe" :src="IPCPath"></iframe>
  </div>
</template>

<script>
export default {
  name: undefined,
  mixins: [],
  components: {},
  props: {
    IPCPath: {
      type: String,
      require: true
    }
  },
  data: function () {
    return {}
  },
  computed: {},
  watch: {},
  created: function () {},
  mounted: function () {
    if (!this.IPCPath) {
      this.$router.push({ name: 'IPC' })
    }
  },
  destroyed() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.IPC-Iframe {
  width: 100%;
  height: calc(100vh - 48px - 32px - 8px);
  border: none;
}
</style>
